import Link from 'next/link';

import { CustomImage } from '@components/Image';
import { TrendingItem } from '@interfaces/Menu';

export const MenuTrending = ({ items }: { items: TrendingItem[] | null }) => (
  <div>
    <h3 className="text-grey-dark dark:text-white">
      <strong>What's trending</strong>
    </h3>
    <div className="my-4 grid grid-cols-2 gap-4">
      {items &&
        items.map((item, index) => (
          <Link
            key={`trending${index}`}
            prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
            href={item.slug}
            className="gae-trending-item"
          >
            <CustomImage image={item.image} width={240} height={240} />
          </Link>
        ))}
    </div>
  </div>
);
