// TODO add overlay and default image to tehse
export const IMAGE_FIELDS = `//groq
  _type,
  asset,
  "altText": coalesce(altText, alt, ""),
  "border": coalesce(border, false),
  "caption": coalesce(caption, ''),
  defaultImage,
  hotspot,
  overlay,
  noFollow,
  "fullWidth": coalesce(fullWidth, false),
  "floatLeft": coalesce(floatLeft, false),
  "floatRight": coalesce(floatRight, false),
  "internalLink": internalLink->{"s": slug.current}.s,
  "link": coalesce(link, ""),
  "newTab": coalesce(newTab, false),
  "ratio": @.asset->{metadata}.metadata{dimensions}.dimensions{width}.width / @.asset->{metadata}.metadata{dimensions}.dimensions{height}.height,
  "width": @.asset->{metadata}.metadata{dimensions}.dimensions{width}.width,
  "lqip": @.asset->{metadata}.metadata{lqip}.lqip`;

export const CLOUDINARY_ASSET_FIELDS = `//groq
  "_type": asset._type,
  "_key": asset._key,
  "publicId": asset.public_id,
  "altText": coalesce(altText, alt, ""),
  "border": coalesce(border, false),
  "caption": coalesce(caption, ''),
  defaultImage,
  overlay,
  noFollow,
  "fullWidth": coalesce(fullWidth, false),
  "floatLeft": coalesce(floatLeft, false),
  "floatRight": coalesce(floatRight, false),
  "internalLink": internalLink->{"s": slug.current}.s,
  "link": coalesce(link, ""),
  "newTab": coalesce(newTab, false),
  "ratio": asset.width / asset.height,
  "width": asset.width
`;

export const PRODUCT_VARIANTS_FIELDS = `//groq
  coalesce(variants[]->{
      ...,
      "images": coalesce(images[]{
        @.asset._type == "cloudinary.asset" => {
          ${CLOUDINARY_ASSET_FIELDS}
        },
        @._type == "image" => {
          ${IMAGE_FIELDS}
        },
      }, []),
      "optionTags": coalesce(optionTags[], []),
      "options": coalesce(options[], []),
      "scheduledDatetimeAll": coalesce(scheduledDatetimeAll, '1970-01-01T00:00:00.000Z'),
      "scheduledDatetimeBanter": coalesce(scheduledDatetimeBanter, '1970-01-01T00:00:00.000Z'),
      "scheduledDatetimeLoggedIn": coalesce(scheduledDatetimeLoggedIn, '1970-01-01T00:00:00.000Z'),
    }, [])
`;

export const CUSTOM_FORM_FIELDS = `//groq
  "_type": "customForm",
  title,
  "loggedInOnly": coalesce(loggedInOnly, false),
  "uniqueEntry": coalesce(uniqueEntry, false),
  "fieldsets": fieldsets[] {
    _key,
    title,
    "inputs": inputs[] {
      _key,
      _type == "formInput" => @{
        _type,
        "name": select(name == "other" => nameOther, name),
        label,
        placeholder,
        type,
        "options": options[]{
          label,
          "value": coalesce(value, label),
        },
        "randomiseOptions": coalesce(randomiseOptions, false),
        "validation": validation {
          "required": coalesce(required, false),
          minLength, 
          maxLength, 
          "min": minValue, 
          "max": maxValue,
        }
      },
      _type == "content" => @{
        _type,
        content[]{
          ...,
          markDefs[]{
            ...,
            _type == "internalLink" => {
              "slug": @.item->slug,
            } 
          }
        }
      },
    },
  },
  "emails": emails[]{
    recipient,
    postmarkTemplateId,
    replyToFormField,
    "replyToCS": coalesce(replyToCS, false),
  },
  _id
`;

export const FORM_FIELDS = `//groq
_type == "form" => @->{
  _type == "customForm" => @{
    ${CUSTOM_FORM_FIELDS}
  },
  _type == "form" => @{
    title,
    formType,
    klaviyoFormId,
    shortStackFormId,
    shortStackV2FormId,
    shortStackFormHeight
  },
},
`;

export const AUTHOR = `//groq
"author": author->{
  name,
  "slug": slug.current,
  "image": select(image._type == "image" => image{
    ${IMAGE_FIELDS}
  }, image._type == "cloudinaryAsset" => image{
    ${CLOUDINARY_ASSET_FIELDS}
  }),
  singleLineDescription,
  shortDescription,
  ambassador,
  facebook,
  instagram,
  twitter,
  tiktok,
  linkedIn,
  website,
  youTube,
  "noFollow": coalesce(noFollow, false),
  "profilePage": coalesce(profilePage, true),
}`;
