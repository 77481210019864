/**
 * Used to set the max width and centre content
 * Also allows for children to go full-width with a class when required
 */

import { createElement, ReactElement, ReactNode } from 'react';

import { mergeStyles } from '@lib/styles';

export function Container({
  children,
  as = 'div',
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  as?: string;
} & Record<string, any>): ReactElement {
  return createElement(
    as,
    {
      ...props,
      className: mergeStyles(
        className ?? '',
        'grid grid-cols-[1fr_min(theme(width.maxWidth),_calc(100%_-_16px))_1fr] max-w-full bg-transparent [&>.full-bleed]:col-span-full [&>.full-bleed]:mx-0 [&>*]:col-start-2 [&>*]:mx-1 tiny:[&>*]:mx-4 sm:[&>*]:mx-5'
      ),
    },
    children
  );
}
