import Link from 'next/link';
import React, { ReactElement, useContext } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { CustomImage } from '@components/Image';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import {
  BaseItem,
  AdditionalResources as IAdditionalResources,
  InternalLink,
  PossibleMenuItem,
} from '@interfaces/Menu';
import { mergeStyles } from '@lib/styles';

const hasLink = (item: PossibleMenuItem): item is InternalLink | BaseItem =>
  'slug' in (item as InternalLink | BaseItem);

const hasImage = (item: PossibleMenuItem): item is BaseItem =>
  'image' in (item as BaseItem);

const MenuItem = ({
  data,
  onItemSelection,
  textSize,
  isLastItem,
}: {
  data: PossibleMenuItem;
  onItemSelection: (_: string) => void;
  textSize: string;
  isLastItem: boolean;
}): ReactElement => (
  <>
    <div
      className="clickableNoLine gae-menu-item group flex cursor-pointer items-center"
      onClick={() => onItemSelection(data.title)}
    >
      {hasImage(data) && (
        <div className="mr-4 min-w-max rounded-sm	shadow-[0_0_0_2px_white,0_0_0_4px_hsl(0,0%,80%)] dark:shadow-[0_0_0_2px_black,0_0_0_4px_hsl(0,0%,40%)]">
          <CustomImage image={data.image} width={50} height={50} />
        </div>
      )}
      <div className="flex-auto justify-center">
        <h3>
          <strong
            className={mergeStyles(
              textSize,
              `flex justify-between pt-1 underline group-hover:decoration-orange group-hover:decoration-3`
            )}
          >
            {data.title}
            {'items' in data && <FiChevronRight />}
          </strong>
        </h3>
        <Paragraph className="m-[0px] min-h-3 text-sm font-normal no-underline">
          {'description' in data ? data.description : ''}
        </Paragraph>
      </div>
    </div>
    <HorizontalDivider
      className={isLastItem ? 'my-4' : 'my-4 via-grey-mid dark:via-grey-light'}
    />
  </>
);

export const MenuContent = ({
  menuToDisplay,
  onItemSelection,
  additionalResources,
}: {
  menuToDisplay: PossibleMenuItem[];
  onItemSelection: (_: string) => void;
  additionalResources: IAdditionalResources | undefined;
}): ReactElement => {
  const {
    menuBreadcrumbs: [menuBreadcrumbs],
  } = useContext<UIContextInterface>(UIContext);

  return (
    <>
      {menuToDisplay &&
        menuToDisplay.map((i, idx) => {
          const menuItemContent = (
            <MenuItem
              data={i}
              key={`${i.title}_${idx}`}
              onItemSelection={onItemSelection}
              textSize={menuBreadcrumbs.length > 0 ? 'text-xl' : 'text-2xl'}
              isLastItem={idx === menuToDisplay.length - 1}
            />
          );

          return hasLink(i) && typeof i.slug == 'string' ? (
            <div key={i.title}>
              <Link
                prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
                href={i.slug}
              >
                {menuItemContent}
              </Link>
            </div>
          ) : (
            menuItemContent
          );
        })}
      {/* additional resources need to be here so they fall within the staggered delay tree */}
      {additionalResources && (
        <>
          <div key={additionalResources.title} className="my-6 text-lg italic">
            <strong>{additionalResources.title}</strong>
          </div>
          {additionalResources.items.map((i) => (
            <div key={i.title}>
              <TextLink href={i.slug}>{i.title}</TextLink>
              <HorizontalDivider className="my-4 via-grey-mid dark:via-grey-light" />
            </div>
          ))}
        </>
      )}
    </>
  );
};
