import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { Loading } from '@components/Alerts/Loading';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { SlideInPanel } from '@components/SlideInPanel/SlideInPanelNoPortal';
import { useMenuData } from '@hooks/useMenuData';
import {
  AdditionalResources as IadditionalResources,
  MenuBannerItem,
  PossibleMenuItem,
  RootMenuItem,
  SubheaderDetails,
  SubmenuItem,
  TrendingItem,
} from '@interfaces/Menu';

import { MenuContent } from './MenuContent';
import { MenuHeader } from './MenuHeader';
import { MenuNavBar } from './MenuNavBar';
import { MenuTrending } from './MenuTrending';

// #region Type Guards
const isSubmenuItem = (item: PossibleMenuItem): item is SubmenuItem =>
  (item as SubmenuItem).type === 'subMenuItem';

const isRootItem = (item: PossibleMenuItem): item is RootMenuItem =>
  (item as RootMenuItem).type === 'rootMenuItem';

const isMenuItem = (
  item: PossibleMenuItem
): item is RootMenuItem | SubmenuItem =>
  isRootItem(item) || isSubmenuItem(item);
// #endregion

const findTitle = (
  arr: (RootMenuItem | SubmenuItem)[],
  target: string
): RootMenuItem | SubmenuItem | undefined =>
  arr.find((x: RootMenuItem | SubmenuItem) => x.title === target);

export const Menu = (): ReactElement => {
  const {
    displayMenu: [menuIsOpen, setMenuIsOpen],
    menuBreadcrumbs: [menuBreadcrumbs, setMenuBreadcrumbs],
  } = useContext<UIContextInterface>(UIContext);
  const {
    menuData,
    isLoading,
    trendingItems,
  }: {
    menuData: RootMenuItem[];
    isLoading: boolean;
    trendingItems: TrendingItem[] | null;
  } = useMenuData();
  const router = useRouter();

  const [menuToDisplay, setMenuToDisplay] = useState<PossibleMenuItem[]>([]);
  const [animationDir, setAnimationDir] = useState<string>('left');
  const [bannerInfo, setBannerInfo] = useState<MenuBannerItem | undefined>();
  const [additionalResources, setAdditionalResources] = useState<
    IadditionalResources | undefined
  >();
  const [subheaderDetails, setSubheaderDetails] = useState<
    SubheaderDetails | undefined
  >();

  const getBanner = () => {
    // assuming only one menu item will be assigned a banner to display, this will just find first instance
    const bannerToDisplay = menuData.find((x) => x.menuBanner?.image);
    // assuming there will be times with no banner, if no banner then display nothing
    if (bannerToDisplay && bannerToDisplay.menuBanner) {
      const { menuBanner } = bannerToDisplay;
      setBannerInfo(menuBanner);
    }
  };

  const setAdditionalMenuSections = (menu?: PossibleMenuItem) => {
    // if menu is not passed down, it means to reset both sections to empty, should just be the case for navigating back to root menu
    // if a subheader link and extended description are preset => set, else reset to undefined so previous details are removed
    setSubheaderDetails(
      menu && isMenuItem(menu)
        ? {
            link: menu.internalLink,
            description: menu.extendedDesc,
          }
        : undefined
    );
    // if available, set additional resources, otherwise reset
    setAdditionalResources(
      menu && isMenuItem(menu) && menu.additionalResources
        ? menu.additionalResources
        : undefined
    );
  };

  const findSubmenu = (
    breadcrumbs: string[]
  ): { submenu: PossibleMenuItem; submenuItems: PossibleMenuItem[] } => {
    // setting type to any so that it doesn't get angry when reassigned in the while loop
    let submenu: any = menuData;
    // loop through selection tree and grab items and reassign at each level to arrive at latest
    // due to the structure of the nested levels
    let i = 0;
    while (i < breadcrumbs.length) {
      submenu =
        i === breadcrumbs.length - 1
          ? findTitle(submenu, breadcrumbs[i])
          : findTitle(submenu, breadcrumbs[i])?.items;

      i++;
    }

    return { submenu, submenuItems: submenu?.items };
  };

  const handleBannerRedirect = () => {
    if (bannerInfo)
      router.push(bannerInfo.links[0].slug ?? bannerInfo.links[0].fallback);
  };

  const onItemSelection = (title: string) => {
    setAnimationDir('left');

    const { submenu, submenuItems } = findSubmenu([...menuBreadcrumbs, title]);
    // if a selection with nested items, display nested items
    if (isMenuItem(submenu)) {
      // update items, breadcrumbs, and subheader link if available
      setMenuToDisplay(submenuItems);
      setMenuBreadcrumbs([...menuBreadcrumbs, title]);
      setAdditionalMenuSections(submenu);
    }
    // else if selection contains slug, redirect to new page and close menu
    // above is handled in link component in MenuItem
  };

  const onNavigationBack = () => {
    setAnimationDir('right');
    const selectionsCopy = [...menuBreadcrumbs];
    // remove the level a customer is currently looking at
    selectionsCopy.pop();
    // if a user has navigated back to root menu, display root menu
    // else show updated nested menu items
    if (selectionsCopy.length === 0) {
      setMenuToDisplay(menuData);
      setAdditionalMenuSections();
    } else {
      const { submenu, submenuItems } = findSubmenu(selectionsCopy);
      // set to data to be displayed
      setMenuToDisplay(submenuItems);
      setAdditionalMenuSections(submenu);
    }

    // update selections to have last item removed
    setMenuBreadcrumbs(selectionsCopy);
  };

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      let menuToSet: PossibleMenuItem[] = menuData;
      // on rerender, you want to set user back menu level they previously visited
      // if breadcrumbs exist, set level to display and reset subheaderlink
      if (menuBreadcrumbs.length > 0) {
        const { submenu, submenuItems } = findSubmenu(menuBreadcrumbs);
        menuToSet = submenuItems;
        setAdditionalMenuSections(submenu);
      }

      getBanner();
      setMenuToDisplay(menuToSet);
    }
  }, [menuData]);

  useEffect(() => {
    // if a redirect occurs while menu is open, then close
    // this includes clicking on any links in menu
    if (menuIsOpen) setMenuIsOpen(false);
  }, [router]);

  return (
    <SlideInPanel
      isOpen={menuIsOpen}
      toggleState={setMenuIsOpen}
      displayX={false}
      from="left"
      testId="menu-panel"
    >
      {/* putting this here so that it lies on top of the body, it's absolutely positioned */}
      <MenuNavBar onNavigationBack={onNavigationBack} />
      <div className="self-justify-center absolute left-[3%] top-9 w-[94%] text-black dark:text-white">
        <MenuHeader
          bannerInfo={bannerInfo}
          handleBannerRedirect={handleBannerRedirect}
          details={subheaderDetails}
          animationDir={animationDir}
        />
        <HorizontalDivider className={'mb-4 mt-0'} />
        <div
          className={
            animationDir === 'right'
              ? 'staggered-delay-from-right'
              : 'staggered-delay-from-left'
          }
        >
          {isLoading ? (
            <div className="mt-[80px]">
              <Loading />
            </div>
          ) : (
            <>
              <MenuContent
                menuToDisplay={menuToDisplay}
                onItemSelection={onItemSelection}
                additionalResources={additionalResources}
              />
              {
                // only show if at root of menu and items are present
                !menuBreadcrumbs.length && trendingItems && (
                  <MenuTrending items={trendingItems} />
                )
              }
            </>
          )}
        </div>
      </div>
    </SlideInPanel>
  );
};
