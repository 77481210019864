import { useEffect, useState } from 'react';
import useSWR from 'swr';

import {
  BaseItem,
  InternalLink,
  TrendingItem as ITrendingItem,
  RootMenuItem,
  SubmenuItem,
  SubmenuItemReference,
} from '@interfaces/Menu';
import {
  rootMenuQuery,
  subMenuQuery,
  whatsTrendingQuery,
} from '@lib/queries/sanity/menuV2';
import { cdnClient as client } from '@lib/sanityClient';

import { useOrders } from './orders/useOrders';
import { useWishlist } from './wishlist/useWishlist';

const isSubmenuReference = (item: any): item is SubmenuItemReference =>
  (item as SubmenuItemReference).type === 'subMenuItem' && 'ref' in item;

export const useMenuData = (): {
  menuData: RootMenuItem[];
  isLoading: boolean;
  trendingItems: ITrendingItem[] | null;
} => {
  const { data: wishlist } = useWishlist();
  const { data: orderedProducts } = useOrders();

  const [menuData, setMenuData] = useState<RootMenuItem[]>([]);

  // fetch all root menu items
  const { data: rootItems, isLoading: rootIsLoading } = useSWR(
    rootMenuQuery,
    async () => await client.fetch(rootMenuQuery),
    {
      refreshInterval: 3600000, // an hour
    }
  );

  // fetch all submenu items
  const { data: allSubMenuItems, isLoading: subMenuIsLoading } = useSWR(
    subMenuQuery,
    async () => await client.fetch(subMenuQuery),
    {
      refreshInterval: 3600000, // an hour
    }
  );

  // fetch trending items
  const { data: trendingData, isLoading: trendingIsLoading } = useSWR(
    whatsTrendingQuery,
    async () => await client.fetch(whatsTrendingQuery),
    {
      refreshInterval: 3600000, // an hour
    }
  );

  useEffect(() => {
    // this function handles recursion, overrides and standardization
    const updateSubmenuReferences = async (
      initSubmenuLevel: (SubmenuItemReference | BaseItem | InternalLink)[]
    ) => {
      const finalSubmenuLevel = initSubmenuLevel.map(async (i) => {
        // if item is a reference to another submenu
        if (isSubmenuReference(i) && allSubMenuItems) {
          // find the submenu by ref value in list of all submenu references
          const interim = allSubMenuItems.find(
            (item: SubmenuItem) => item.id === i.ref
          );
          // update possible override values for submenu items
          for (const override of ['description', 'title']) {
            if (i[override]) {
              interim[override] = i[override];
            }
          }
          // for referenced submenus, continue to recursively build tree at items
          // all submenus that are references should in theory always have more items
          return {
            ...interim,
            items: await updateSubmenuReferences(interim.items),
          };
        }
        // if not a reference, just return as is
        // this includes internal links, products, categories, pages, ...etc
        return i;
      });
      // await all and return
      return await Promise.all(finalSubmenuLevel);
    };

    const buildNestedTree = async (data: RootMenuItem[]) => {
      // begin building menu structure by replacing all submenu references with matching item in allSubMenuItems
      const completeMenu = data.map(async (rootItem) => ({
        ...rootItem,
        items: await updateSubmenuReferences(rootItem.items),
      }));

      const nextMenuData = await Promise.all(completeMenu);

      // If we have previous orders add it to the top of the menu
      if (orderedProducts && orderedProducts.length > 0) {
        const rootPreviousOrders = {
          title: 'My Previously Ordered',
          description: "Products that I've ordered before",
          extendedDesc:
            "Check it out, it's up to eight of your previously ordered products!",
          additionalResources: null,
          menuBanner: null,
          internalLink: {
            slug: '/account/orders',
            title: 'View All Previously Ordered Products',
          },
          sortOrder: 1,
          type: 'rootMenuItem',
          items: orderedProducts.slice(0, 8).map((product) => {
            const {
              name: title,
              slug,
              images,
              shortDescription: description,
            } = product;
            return {
              title,
              description,
              slug,
              image: images[0],
              type: 'product',
            };
          }),
        };
        nextMenuData.unshift(rootPreviousOrders);
      }

      // If we have a wishlist (favourites) add it to the top of the menu
      if (wishlist) {
        const rootWishlist = {
          title: 'My Favourites',
          description: 'Products added to your favourites list',
          extendedDesc:
            'In no particular order, we present up to eight of your favourited products! 🥳',
          additionalResources: null,
          menuBanner: null,
          internalLink:
            wishlist.products.length > 8
              ? { slug: '/account/favourites', title: 'View All Favourites' }
              : null,
          sortOrder: 1,
          type: 'rootMenuItem',
          items: wishlist.products.slice(0, 8).map((product) => {
            const {
              name: title,
              slug,
              images,
              shortDescription: description,
            } = product;
            return {
              title,
              description,
              slug,
              image: images[0],
              type: 'product',
            };
          }),
        };
        nextMenuData.unshift(rootWishlist);
      }
      setMenuData(nextMenuData);
    };

    // once data is fetched, build tree
    if (rootItems && allSubMenuItems) buildNestedTree(rootItems);
  }, [allSubMenuItems, rootItems, wishlist, orderedProducts]);

  return {
    menuData,
    isLoading: rootIsLoading || subMenuIsLoading,
    trendingItems: !trendingIsLoading ? trendingData[0].trendingItems : null, // don't want trending to prevent the rest of the menu to load
  };
};
