import { useRouter } from 'next/router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, ButtonType } from '@components/Button';
import { Input } from '@components/FormComponents';
import { PopularSearches } from '@components/PopularSearches/PopularSearches';

export const MenuSearch = ({ cookieVariant }: { cookieVariant: number }) => {
  const router = useRouter();

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    router.push(`/search-results-page?q=${data.query}`);
    // small delay so that router can take effect
    setTimeout(() => rhfMethods.reset(), 2000);
  };

  return (
    <div className="flex flex-col">
      <FormProvider {...rhfMethods}>
        <form onSubmit={rhfMethods.handleSubmit(onSubmit)} className="flex">
          <div className="mb-2 w-3/4">
            <Input
              type="search"
              name="query"
              label=""
              placeholder="Find products, flavours and more"
            />
          </div>
          <Button className="w-1/4 p-[21px]" type={ButtonType.submit}>
            Search
          </Button>
        </form>
      </FormProvider>
      {cookieVariant === 2 && <PopularSearches />}
    </div>
  );
};
